import React from 'react';
import styled from 'styled-components';
import Play from '../assets/play-big.svg';
import Stop from '../assets/stop.svg';
import media from '../style/media';
import Spinner from './Spinner';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  width: 58px;
  ${media.tablet`
    height: 88px;
    width: 88px;
  `}
`;

const Button = styled.div`
background-color: #3E9647;
color: #fff;
padding: 0.6em;
border: none;
width: 90%;
border-radius: 15px;
cursor: pointer;
text-align:center;
z-index:80;
@media (max-width: 1000px) {
 width:4em;
 
}
`;


const Icon = styled.img`
  width: 100%;
  padding: 52px;
  ${media.tablet`
    padding: 32px;
  `}
`;

export interface IStartButton {
  /**
   *  Displays a spinner when isLoading is true
   */
  isLoading;
  /**
   *  function triggered on button click
   */
  onClick: () => void;
  /**
   *  Displays whether the current session state is measuring
   */
  isMeasuring: boolean;
  /**
   *  Display an error message about LICENSE
   */
  messageLicense;
}

const StartButton = ({ isLoading, onClick, isMeasuring, messageLicense }: IStartButton) => {
  return (
    <>
      {isMeasuring || (messageLicense != '') ? (
        <>
          <Spinner />
          <div>
            {messageLicense}
          </div>
        </>
      ) : (
        <Button onClick={onClick}>
          INICIAR
        </Button>
      )}
    </>
  );
};

export default StartButton;
