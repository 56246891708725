import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Card } from './Card';
import { useLocation } from 'react-router-dom';
import { Flex } from './shared/Flex';
import SALA_ESPERA from '../assets/SALA_ESPERA.jpg';

const Container = styled(Flex)`
  height: 100vh;
  width: 100vw;
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow:hidden;
  background: url(${SALA_ESPERA});
  background-color: rgb(106 0 0 / 40%);
  background-blend-mode: soft-light;
  background-size: cover;
`;

export const Select = () => {

  return (
    <Container>
      <Card></Card>
    </Container>
  );
};